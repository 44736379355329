<template>
  <div>
    <div class="backdrop"></div>
    <dialog open>
      <div class="alpha">

        <div class="first-part">
          <img src="@/assets/transfer-icon-modal.svg" alt="withdraw-icon"/>
          <i class='bx bx-x' @click="$emit('close')"></i>
        </div>

        <div class="second-part">
          <p class="second-text-1">Transfer to other banks</p>
          <form @submit.prevent="fundTransfer">

            <div class="input-form-2">
              <input type="number" v-model="model.amount" placeholder="Enter amount" class="input-form-1"  />
            </div>

            <div class="input-form-2">
              <input type="text" v-model="model.narration"  placeholder="Enter narration" class="input-form-1"  />
            </div>

            <div class="input-form-2">
              <input type="text" v-model="model.debitAccountName" placeholder="Enter your Debit Account Name" class="input-form-1"  />
            </div>

            <div class="input-form-2">
              <input type="number" v-model="model.debitAccountNumber" placeholder="Enter your Debit Account Number" class="input-form-1"  />
            </div>

            <br/>
            <div class="separate">
              <button class="btn" @click="handleClick">Back</button>
              <base-button :loading="loading">Proceed</base-button>
            </div>

          </form>
        </div>


      </div>

    </dialog>
  </div>
</template>

<script>
import BaseButton from "@/components/baseComponents/buttons/BaseButton.vue";
import FundTransferRequest from "@/model/request/FundTransferRequest";
import {mapState} from "vuex";
import StoreUtils from "@/util/baseUtils/StoreUtils";


export default {
  name: "TransferToOtherBanksModal2",
  components: {BaseButton},
  emits: ['close', 'open'],
  data() {
    return {
      model: new FundTransferRequest().fundTransfer,
    };
  },
  computed:{
    ...mapState({
      loading: state => state.fundTransfer.loading,
      auth: state => state.auth,
      userInfo: state => state.auth.userInfo,
    }),
    transferToOtherBanksForm() {
      return StoreUtils.rootGetters(StoreUtils.getters.fundTransfer.getTransferToOtherBanksForm)
    },
  },
  methods:{
    handleClick() {
      this.$emit('open');
      this.$emit('close');
    },
    async fundTransfer() {
      await StoreUtils.dispatch(StoreUtils.actions.fundTransfer.fundTransferFundTransfer, {
        amount: this.model.amount,
        bankName: this.transferToOtherBanksForm.bankName,
        bankCode: this.transferToOtherBanksForm.bankCode,
        creditAccountName: this.transferToOtherBanksForm.creditAccountName,
        creditAccountNumber: this.transferToOtherBanksForm.creditAccountNumber,
        debitAccountName: this.model.debitAccountName,
        debitAccountNumber: this.model.debitAccountNumber,
        narration: this.model.narration,
        sessionId: this.transferToOtherBanksForm.sessionId,
        reference: this.transferToOtherBanksForm.reference,
        sourceType: this.transferToOtherBanksForm.sourceType
      });
      await this.$emit('close');
    }
  },
}
</script>

<style scoped >

.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 10;
  background-color: rgba(0, 0, 0, 0.7);
}


dialog {
  position: fixed;
  top: 18vh;
  width: 32rem;
  height: 20rem;
  left: calc(50% - 9rem);
  margin: 0;
  background-color: transparent;
  z-index: 100;
  border: none;
  animation: modal 0.3s ease-out forwards;
}

.alpha{
  position: relative;
  display: block;
  overflow: hidden;
  width: 400px;
  height: 500px;
  /*height: auto;*/
  padding: 24px;
  border-style: solid;
  border-width: 1px;
  border-color: rgba(3, 28, 67, 0.1);
  border-radius: 10px;
  background-color: #fff;
  box-shadow: 0 0 34px 0 rgba(3, 28, 67, 0.13);
}

.first-part{
  display: flex;
  justify-content: space-between;
}

.bx-x{
  font-size: 25px;
  padding-top: 2px;
}

.separate{
  display: flex;
}

.btn{
  margin-right: 5%;
  align-items: center;
  /*padding: 10px 28px;*/
  gap: 8px;
  width: 360px;
  height: 48px;
  background: #FFFFFF;
  border: 1px solid #D0D5DD;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
}

.second-text-1{
  font-weight: 600;
  font-size: 18px;
  line-height: 28px;
  color: #101828;
  margin-top: 2%;
  margin-bottom: 4%;
}

.input-form-2{
  display: flex;
  justify-content: center;
  margin-top: 2%;
  margin-bottom: 2%;
}

.input-form-1{
  order: 1;
  width: 100%;
  padding: 12px 20px;
  margin: 8px 0;
  display: inline-block;
  box-sizing: border-box;
  margin-bottom: 2%;
}

input {
  box-sizing: border-box;
  border: 1px solid #D0D5DD;
  border-radius: 8px;
  -webkit-transition: 0.3s;
  padding-top: 12px;
  padding-bottom: 12px;
  transition: 0.3s;
  outline: none;
  color: #667085;
  letter-spacing: 0.5px;
}

input:focus {
  border: 1px solid #24405A;
}


@keyframes modal {
  from {
    opacity: 0;
    transform: translateY(-50px) scale(0.9);
  }

  to {
    opacity: 1;
    transform: translateY(0) scale(1);
  }
}
@media (max-width: 500px) {
  dialog {
    top: 5vh;
    width: 27rem;
    height: 20rem;
    left: calc(50% - 12.4rem);
    right: 30px;
  }
  h3{
    font-size: 20px;
  }
  p{
    font-size: unset;
  }
}
</style>