import swal from "sweetalert";
import StoreUtils from "@/util/baseUtils/StoreUtils";
import FundTransferService from "@/service/FundTransferService";
import FundTransferRequest from "@/model/request/FundTransferRequest";
import FundTransferResponse from "@/model/response/FundTransferResponse";


export const state = {
    loading: false,
    nameEnquiry: new FundTransferResponse().nameEnquiry,
    bankList: new FundTransferResponse().bankList,
    postSingleTransaction: new FundTransferResponse().postSingleTransaction,
    fundTransfer: new FundTransferResponse().fundTransfer,
    read: new FundTransferResponse().read,
    transactionStatusQuery: new FundTransferResponse().transactionStatusQuery,
    transferToOtherBanksForm: null,
    transferToAfropayForm: null
};

export const getters = {
    getNameEnquiry: (state) => {
        return state.nameEnquiry;
    },
    getBankList: (state) => {
        return state.bankList;
    },
    getPostSingleTransaction: (state) => {
        return state.postSingleTransaction;
    },
    getFundTransfer: (state) => {
        return state.fundTransfer;
    },
    getRead: (state) => {
        return state.read;
    },
    getTransactionStatusQuery: (state) => {
        return state.transactionStatusQuery;
    },
    getTransferToOtherBanksForm: (state) => {
        return state.transferToOtherBanksForm;
    },
    getTransferToAfropayForm: (state) => {
        return state.transferToAfropayForm;
    },
};


export const mutations = {
    updateLoading(state, payload){
        state.loading = payload
    },
    updateNameEnquiry(state, payload){
        state.nameEnquiry = payload
    },
    updateBankList(state, payload){
        state.bankList = payload
    },
    updatePostSingleTransaction(state, payload){
        state.postSingleTransaction = payload
    },
    updateFundTransfer(state, payload){
        state.fundTransfer = payload
    },
    updateRead(state, payload){
        state.read = payload
    },
    updateTransactionStatusQuery(state, payload){
        state.transactionStatusQuery = payload
    },
    updateTransferToOtherBanksForm(state, payload){
        state.transferToOtherBanksForm = payload
    },
    updateTransferToAfropayForm(state, payload){
        state.transferToAfropayForm = payload
    },
};

export const actions = {
    // eslint-disable-next-line no-empty-pattern
    fundTransferNameEnquiry({}, payload = new FundTransferRequest().nameEnquiry){
        StoreUtils.commit(StoreUtils.mutations.fundTransfer.updateLoading, true)
        return FundTransferService.callNameEnquiryApi(payload).then(response=>{
            StoreUtils.commit(StoreUtils.mutations.fundTransfer.updateLoading, false)
            let responseData = response.data
            if (responseData.responseCode === "00"){
                swal("Success",responseData.responseMessage, 'success').then(r => console.log(r))
                StoreUtils.commit(StoreUtils.mutations.fundTransfer.updateNameEnquiry, responseData)
            }else{
                swal("Error",responseData.responseMessage, 'error').then(r => console.log(r))
            }
        }).catch(error=>{
            console.log(error)
            StoreUtils.commit(StoreUtils.mutations.fundTransfer.updateLoading, false)
        })
    },

    //eslint-disable-next-line no-empty-pattern
    fundTransferBankList({}, payload = {}){
        StoreUtils.commit(StoreUtils.mutations.fundTransfer.updateLoading, true)
        return FundTransferService.callBankListApi(payload).then(response=>{
            StoreUtils.commit(StoreUtils.mutations.fundTransfer.updateLoading, false)
            let responseData = response.data
            if (responseData.responseCode === '00'){
                StoreUtils.commit(StoreUtils.mutations.fundTransfer.updateBankList, responseData)
            }else{
                swal("Error",responseData.responseMessage, 'error').then(r => console.log(r))
            }
        }).catch(error=>{
            console.log(error)
            StoreUtils.commit(StoreUtils.mutations.fundTransfer.updateLoading, false)
        })
    },

    // eslint-disable-next-line no-empty-pattern
    fundTransferPostSingleTransaction({}, payload = new FundTransferRequest().postSingleTransaction){
        StoreUtils.commit(StoreUtils.mutations.fundTransfer.updateLoading, true)
        return FundTransferService.callPostSingleTransactionApi(payload).then(response=>{
            StoreUtils.commit(StoreUtils.mutations.fundTransfer.updateLoading, false)
            let responseData = response.data
            if (responseData.responseCode === "00"){
                swal("Success",responseData.responseMessage, 'success').then(r => console.log(r))
                StoreUtils.commit(StoreUtils.mutations.fundTransfer.updatePostSingleTransaction, responseData)
            }else{
                swal("Error",responseData.responseMessage, 'error').then(r => console.log(r))
            }
        }).catch(error=>{
            console.log(error)
            StoreUtils.commit(StoreUtils.mutations.fundTransfer.updateLoading, false)
        })
    },


    // eslint-disable-next-line no-empty-pattern
    fundTransferFundTransfer({}, payload = new FundTransferRequest().fundTransfer){
        StoreUtils.commit(StoreUtils.mutations.fundTransfer.updateLoading, true)
        return FundTransferService.callFundTransferApi(payload).then(response=>{
            StoreUtils.commit(StoreUtils.mutations.fundTransfer.updateLoading, false)
            let responseData = response.data
            if (responseData.responseCode === "00"){
                swal("Success",responseData.responseMessage, 'success').then(r => console.log(r))
                StoreUtils.commit(StoreUtils.mutations.fundTransfer.updateFundTransfer, responseData)
            }else{
                swal("Error",responseData.responseMessage, 'error').then(r => console.log(r))
            }
        }).catch(error=>{
            console.log(error)
            StoreUtils.commit(StoreUtils.mutations.fundTransfer.updateLoading, false)
        })
    },

    //eslint-disable-next-line no-empty-pattern
    fundTransferRead({}, payload = {}){
        StoreUtils.commit(StoreUtils.mutations.fundTransfer.updateLoading, true)
        return FundTransferService.callReadApi(payload).then(response=>{
            StoreUtils.commit(StoreUtils.mutations.fundTransfer.updateLoading, false)
            let responseData = response.data
            if (responseData.responseCode === '00'){
                StoreUtils.commit(StoreUtils.mutations.fundTransfer.updateRead, responseData)
            }else{
                swal("Error",responseData.responseMessage, 'error').then(r => console.log(r))
            }
        }).catch(error=>{
            console.log(error)
            StoreUtils.commit(StoreUtils.mutations.fundTransfer.updateLoading, false)
        })
    },

    // eslint-disable-next-line no-empty-pattern
    fundTransferTransactionStatusQuery({}, payload = new FundTransferRequest().transactionStatusQuery){
        StoreUtils.commit(StoreUtils.mutations.fundTransfer.updateLoading, true)
        return FundTransferService.callTransactionStatusQueryApi(payload).then(response=>{
            StoreUtils.commit(StoreUtils.mutations.fundTransfer.updateLoading, false)
            let responseData = response.data
            if (responseData.responseCode === "00"){
                swal("Success",responseData.responseMessage, 'success').then(r => console.log(r))
                StoreUtils.commit(StoreUtils.mutations.fundTransfer.updateTransactionStatusQuery, responseData)
            }else{
                swal("Error",responseData.responseMessage, 'error').then(r => console.log(r))
            }
        }).catch(error=>{
            console.log(error)
            StoreUtils.commit(StoreUtils.mutations.fundTransfer.updateLoading, false)
        })
    },

};