<template>
  <div class="alpha">
    <dash-board-side-menu/>
    <dash-content>
      <transfer-to-other-banks-modal @close="hideDialog" @open="showDialog3" v-if="dialogIsVisible"/>
      <transfer-to-afropay-modal @close="hideDialog2" @open="showDialog4" v-if="dialogIsVisible2"/>

      <transfer-to-other-banks-modal2 @close="hideDialog3" @open="showDialog"  v-if="dialogIsVisible3"/>
      <transfer-to-afropay-modal2 @close="hideDialog4" @open="showDialog2" v-if="dialogIsVisible4"/>


      <div class="section-4">
        <p class="section-4-header">Funds Transfer</p>

        <div class="forth-section">

          <div class="form-group fg--search">
            <button type="submit"><i class="fa fa-search"></i></button>
            <input type="text" class="input" placeholder="Search transaction..."/>
          </div>

          <div class="action">

            <div class="action-content" @click="showDialog">
              <p>Transfer to other banks</p>
            </div>

            <div class="action-content-2" @click="showDialog2">
              <p>Transfer to AfroPay</p>
            </div>

          </div>

        </div>
      </div>

      <div class="section-5">

        <div class="empty-state" v-if="this.readWalletActivity.data.length === 0">
          <img src="@/assets/empty.svg" alt="empty">
          <p class="empty-state-text-1">You have nothing to see</p>
          <p class="empty-state-text-2">This is where your Wallets will appear</p>
          <p class="empty-state-text-3">
            <i class='bx bx-plus' ></i>
            Fund account
          </p>
        </div>

        <div class="table" v-if="this.readWalletActivity.data.length >0">
          <table>
            <tr>
              <th>Sender</th>
              <th>Receiver</th>
              <th>Amount</th>
              <th>Transaction Reference</th>
              <th>Payment Reference</th>
              <th>Date</th>
              <th>Status</th>
            </tr>

            <tbody v-for="child in paginatedItems" :key="child.key">
            <tr>
              <td>{{child.accountName}}</td>
              <td>{{child.counterPartyAccountName}}</td>
              <td>{{child.amount}}</td>
              <td>{{child.paymentReference | formatTextWithEllipsis}}</td>
              <td>{{child.contractReference | formatTextWithEllipsis}}</td>
              <td>{{child.transactionDate | formatTextWithEllipsis}}</td>
              <td>Successful</td>
            </tr>
            </tbody>

          </table>
          <div class="pagination">
            <button @click="previousPage" :disabled="currentPage === 1" class="previous">Previous</button>
            <div class="page-indicator">
              Page {{ currentPage }} of {{ totalPages }}
            </div>
            <button @click="nextPage" :disabled="currentPage === totalPages" class="previous">Next</button>
          </div>
        </div>


      </div>

    </dash-content>
  </div>
</template>

<script>
import DashBoardSideMenu from "@/components/dashboard/DashBoardSideMenu.vue";
import DashContent from "@/components/baseComponents/dash/DashContent.vue";
import TransferToOtherBanksModal from "@/components/baseComponents/modals/TransferToOtherBanksModal.vue";
import TransferToAfropayModal from "@/components/baseComponents/modals/TransferToAfropayModal.vue";
import TransferToOtherBanksModal2 from "@/components/baseComponents/modals/TransferToOtherBanksModal2.vue";
import TransferToAfropayModal2 from "@/components/baseComponents/modals/TransferToAfropayModal2.vue";
import {mapState} from "vuex";
import StoreUtils from "@/util/baseUtils/StoreUtils";

export default {
  name: "DashBoardFundsTransferView",
  components: {
    TransferToAfropayModal2,
    TransferToOtherBanksModal2,
    TransferToAfropayModal, TransferToOtherBanksModal, DashContent, DashBoardSideMenu},
  data() {
    return {
      dialogIsVisible: false,
      dialogIsVisible2: false,
      dialogIsVisible3: false,
      dialogIsVisible4: false,
      currentPage: 1,
      itemsPerPage: 10,
    };
  },
  computed:{
    ...mapState({
      loading: state => state.fundTransfer.loading,
      auth: state => state.auth,
      userInfo: state => state.auth.userInfo,
      readWalletActivity: state => state.wallet.readWalletActivity,
    }),
    paginatedItems() {
      const startIndex = (this.currentPage - 1) * this.itemsPerPage;
      const endIndex = startIndex + this.itemsPerPage;
      return this.readWalletActivity.data.slice(startIndex, endIndex);
    },
    totalPages() {
      return Math.ceil(this.readWalletActivity.data.length / this.itemsPerPage);
    },
  },
  methods: {
    hideDialog() {
      this.dialogIsVisible = false;
    },
    showDialog() {
      this.dialogIsVisible = true;
    },
    hideDialog2() {
      this.dialogIsVisible2 = false;
    },
    showDialog2() {
      this.dialogIsVisible2 = true;
    },

    hideDialog3() {
      this.dialogIsVisible3 = false;
    },
    showDialog3() {
      this.dialogIsVisible3 = true;
    },

    hideDialog4() {
      this.dialogIsVisible4 = false;
    },
    showDialog4() {
      this.dialogIsVisible4 = true;
    },
    previousPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
      }
    },

    nextPage() {
      if (this.currentPage < this.totalPages) {
        this.currentPage++;
      }
    },

    goToPage(pageNumber) {
      if (pageNumber > 0 && pageNumber <= this.totalPages) {
        this.currentPage = pageNumber;
      }
    },
  },
  async mounted() {
    await StoreUtils.dispatch(StoreUtils.actions.wallet.walletReadWalletActivity,)
  },
}
</script>

<style scoped>
.alpha{
  display: flex;
}
.section-1{
  display: flex;
  justify-content: right;
  align-content: center;
  align-items: center;
  padding-top: 0.5%;
  padding-bottom: 0.5%;
  padding-right: 3%;
  background-color: #FFFFFF;
}
.vl{
  border-left: 1.5px solid #E7E8F0;
  height: 35px;
  margin-left: 1%;
  margin-right: 1%;
}
.profile-name{
  padding-left: 1%;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.01em;
  color: #58596C;
}

.section-4-header{
  padding-left: 1.5%;
  margin-top: 1.5%;
  font-weight: 700;
  font-size: 19px;
  line-height: 25px;
  color: #353542;
}

.fg--search {
  background: white;
  position: relative;
  width: 400px;
  margin-left: 1%;
}
.fg--search input {
  width: 100%;
  padding: 10px;
  padding-left: 15px;
  display: block;
  background: #FFFFFF;
  border: 1px solid #D0D5DD;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 6px;
}

.fg--search button {
  background: transparent;
  border: none;
  cursor: pointer;
  display: inline-block;
  font-size: 10px;
  position: absolute;
  top: 0;
  right: 0;
  padding: 10px;
  margin-top: 5px;
}

.fa-search{
  color: #667085;
  margin-right: 10px;
}

.forth-section{
  display: flex;
  justify-content: space-between;
  margin-left: 1%;
  margin-right: 2%;
  margin-top: 15px;
}

.action{
  margin-right: 1%;
  display: flex;
  align-items: center;
  align-content: center;
}

.action-content{
  display: flex;
  align-items: center;
  align-content: center;
  padding: 8px 9px;
  gap: 8px;
  width: 152px;
  height: 40px;
  background: #1570EF;
  border: 1px solid #1570EF;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 5px;
  margin-right: 2%;
}

.action-content:hover{
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
}


.action-content-2{
  display: flex;
  align-items: center;
  align-content: center;
  padding: 8px 9px;
  gap: 8px;
  width: 135px;
  height: 40px;
  background: #FFFFFF;
  border: 1px solid #D0D5DD;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 5px;
  margin-right: 2%;
}

.action-content-2:hover{
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
}

.action-content p {
  font-weight: 500;
  font-size: 12px;
  color: #FFFFFF;
}


.action-content-2 p {
  font-weight: 500;
  font-size: 12px;
  color: #344054;
}



table {
  border-collapse: collapse;
  width: 100%;
  margin-top: 2%;
}
.table{
  margin-left: 2%;
  margin-right: 3%;
}

tr{
  border: 1px solid #E3EBF6;
}

th {

  background-color: #F9FBFD;
  padding: 10px;
  letter-spacing: 0.5px;
  font-weight: 500;
  font-size: 14px;
  color: #667085;
  text-align: center;
}

td {
  /*border: 1px solid #E3EBF6;*/
  text-align: center;
  align-items: center;
  align-content: center;
  padding: 10px 8px;
  /*letter-spacing: 1px;*/
  color: #667085;
  font-weight: 200;
  font-size: 15px;
  /*border-bottom: 1px solid #E3EBF6;*/
  text-transform: lowercase;
}
td::first-letter {
  text-transform: capitalize;
}

.empty-state{
  text-align: center;
  margin-top: 11%;
  margin-right: 8%;
}


.empty-state-text-1{
  font-weight: 600;
  font-size: 18px;
  line-height: 20px;
  color: #353542;
  padding-top: 0.5%;
  padding-bottom: 0.5%;
}

.empty-state-text-2{
  font-weight: 200;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  color: #353542;
  padding-bottom: 1.25%;
}

.empty-state-text-3{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px 18px;
  gap: 8px;
  width: 150px;
  margin-right: auto;
  margin-left: auto;
  height: 35px;
  background: #0765FF;
  color: #FFFFFF;
  border: 1px solid #0765FF;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 5px;
  font-size: 13px;
}

.empty-state-text-3:hover{
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.4);
}

.pagination{
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: space-between;
  padding-top: 10px;
}

.previous{
  /*display: flex;*/
  /*align-content: center;*/
  /*align-items: center;*/
  text-align: center;
  padding: 8px 14px;
  gap: 8px;
  font-size: 12px;
  width: 120px;
  height: 32px;
  background: #1570EF;
  color: #ffffff;
  border: 1px solid #1570EF;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 4px;
}

.previous:hover{
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.4);
}

.page-indicator{
  color: #667085;
  font-weight: 200;
  font-size: 15px;
}
</style>